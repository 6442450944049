<template>
  <div>
    <PageHeader :title="title" :items="items" :color="'#6297C3'"/>
    <div class="row">
      <YearSidebar :year="year"/>
      <div class="col-lg-9">
        <div class="card">
          <div class="card-body">
            <div class="tabs">
              <div>
                <!-- Tabs links -->
                <ul role="tablist" class="nav nav-tabs" id="__BVID__16__BV_tab_controls_">
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('details')" :activeClass="(this.$route.name == 'yearDetails')?'active':''">Details</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('chapters')" :activeClass="(this.$route.name == 'yearChapters')?'active':''">Lectures</router-link>
                  </li>
                  <!-- <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('videos')" :activeClass="(this.$route.name == 'yearVideos')?'active':''">Videos</router-link>
                  </li> -->
                  <!-- <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('youtube-videos')" :activeClass="(this.$route.name == 'yearYoutubeVideos')?'active':''">Youtube Videos</router-link>
                  </li> -->
                </ul>
              </div>
              <!-- Content of the tab -->
              <div class="tab-content">
                <div role="tabpanel" aria-hidden="false" class="tab-pane active">
                  <slot/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YearSidebar from '../../components/Years/yearSidebar';
import PageHeader from "../../components/Page-header";


/**
 * center profile component
 */
export default {
  name: 'Stage-Layout',
  components: {
    PageHeader,
    YearSidebar
  },
  created(){
  },
  head () {
    return {
      title: `${this.title}`,
    }
  },
  props: ['year'],
  data () {
    return {
      title: 'Stage',
      items: [
        {
          text: 'Work ',
          href: '/'
        },
        {
          text: 'Stages',
          href: '/years'
        },
        {
          text: 'Stage',
          active: true
        }
      ],

    }
  },
  methods: {
    accountLink ($tab) {
      return '/years/' + this.$route.params.year + '/' + $tab
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: flex-start;
}

.buttons {
  color: blue;
}

.QR {
  width: 50%;
  padding: 9px;
}

.Dropdown {
  position: absolute;
  right: 30px;
  margin-top: 0;

}

.button {
//   background-color: rgb(241 245 247);

}

#sidebar-right {
  background: #fff !important;
}

.b-sidebar.b-sidebar-right {
  width: 50%;
}

header.b-sidebar-header {
  box-shadow: 2px -3px 5px;
  z-index: 999;
}

footer.b-sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  box-shadow: 2px 3px 5px;
  z-index: 999;
}

#sidebar-right___title__ {
  font-size: 20px;

  i {
    margin-right: 5px;
  }
}

.sidebar-footer {
  button {
    i {
      color: #FFF;
    }
  }
}

</style>
