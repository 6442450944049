<template>
  <div class="col-lg-3 center">
    <div class="card ">
      <div class="card-body text-center">
        <h3 class="mt-3 mb-0">Stage</h3>
        <div class="text-left mt-3">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tbody>
              <tr>
                <th scope="row">Number :</th>
                <td class="text-muted">{{year.number}}</td>
              </tr>
              <tr>
                <th scope="row">Description :</th>
                <td class="text-muted" >
                  {{year.description}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YearSidebar',
    props: ['year']
  }
</script>

<style scoped>

</style>
